/* Fixed Side Bar */
.flex-item-center:hover {
  background: rgba(0, 0, 0, 0.07) !important;
  border-radius: 0;
  box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 40%);
}

.flex-item-center {
  padding: 16px;
  padding-left: 0;
}
.backFixed {
  top: 30px;
  right: 22px;
  cursor: pointer;
  position: absolute;
}

.profileFix ul {
  margin: 20px 0px;
  padding: 0px 12px;
  list-style-type: none;
}

.profileFix {
  border-top: 2px solid #bbb;
  margin-top: 12px;
  padding-top: 4px;
}

.profileFix li {
  color: #000000;
  font-size: 21px;
  font-weight: bold;
  text-transform: capitalize;
}

.profileFixSecond ul {
  margin: 20px 0px;
  padding: 0px 12px;
  list-style-type: none;
}

.profileFixSecond li img {
  width: 20px;
  margin-left: 0px;
  margin-right: 18px;
}

.profileFixSecond li {
  color: #070707;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.profileFixSignout {
  cursor: pointer;
  color: #3795b0 !important;
  font-size: 16px !important;
}

/* For Corridor Graph */

.corridorGraph {
  background: #fff;
  width: 100%;
  padding-bottom: 3px;
  margin-bottom: 9px;
  border-radius: 0px !important;
}

.corridorGraphSlowness {
  background: #fff;
  width: 100%;
  padding-bottom: 3px;
  margin-bottom: 9px;
  border-radius: 0px !important;
  margin-top: 20px;
}

/* For all Graph */

.graphPadding {
  height: 194px !important;
  padding-bottom: 6px;
}

.yAxisLabel {
  top: auto;
  font-size: 11px;
  margin-top: 100px;
  margin-left: 6px;
  position: absolute;
  transform-origin: 0 0;
  transform: rotate(-90deg);
  color: #3b94b0;
}

.noDataOnGraph {
  position: absolute;
  color: #000;
  top: 44%;
  left: 40%;
}

.timingGraph {
  background: #fff;
  width: 100%;
  padding-bottom: 0px;
  margin-bottom: 12px;
  box-shadow: 1px 1px 1px 0 rgba(35, 36, 37, 0.3);
}

.corridorGraphHeading {
  top: 9px;
  padding: 0;
  margin: 0px 19px;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: var(--interactive-blue);
}

.corridorGraphHeadingC {
  top: 9px;
  padding: 0;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: var(--interactive-blue);
}

.detailsCoridor {
  color: #000;
}

.dialogBtn {
  border: none;
  float: right;
  color: #fff;
  cursor: pointer;
  margin: 0px 5px;
  padding: 5px;
  font-size: 12px;
  border-radius: 4px;
  background: #3795b0;
}

.headerDialogList {
  font-size: 18px;
  text-align: right;
  padding: 12px;
  background: #d4e5e3;
  border-radius: 3px;
  padding: 5px !important;
  margin-bottom: 0px;
}

.headerDialogList label {
  margin: 0px 6px !important;
}

.headerDialogList span {
  float: left;
}

.comapareList {
  padding: 3px;
  margin-bottom: 10px;
  list-style-type: none;
}

.compareBtn {
  width: 100px;
  border: none;
  color: #fff;
  padding: 3px 0px;
  margin-top: 18px;
  margin-left: 12px;
  border-radius: 2px;
  background: #319ebc;
}

.fixedComparePlan {
  top: 0;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  background: whitesmoke;
  flex-direction: column;
  left: calc(6% + 480px);
  box-shadow: 2px 0 6px rgb(50 52 50);
  width: 480px;
}

.react-daterange-picker__inputGroup__input {
  outline: none;
}

.react-daterange-picker {
  top: -3px;
  border: none;
  color: #fff;
  box-shadow: none;
  margin-right: 6px;
  margin-right: 5px;
  border-radius: 2px;
  position: relative;
  background: #219ebc;
  background: #3daac4;
  font-size: 12px !important;
}

.react-daterange-picker__wrapper {
  border: 0px solid !important;
}

.react-daterange-picker__calendar .react-calendar {
  background: #fff;
  color: #000;
  border: none;
  box-shadow: 2px 1px 3px #efefef;
}

.react-calendar__tile--active {
  background: #2d7d90;
  color: white;
}

.react-calendar__tile--hasActive {
  background: #2d7d90;
  color: white;
}

.react-calendar__navigation {
  background: #2d7d90;
}

.react-calendar__navigation button {
  color: #fff;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #3daac4;
}

.react-calendar__navigation button[disabled] {
  background-color: #f5f5f5;
  color: #000;
}

.react-daterange-picker__inputGroup__input {
  color: #fff;
}

.react-daterange-picker__inputGroup {
  color: #fff;
}

.react-daterange-picker__range-divider {
  color: #fff;
}

.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
  stroke: #ffffff;
}

.react-daterange-picker__button svg {
  display: inherit;
  stroke: #fff;
}

.btnDate {
  top: -3px;
  border: none;
  color: #fff;
  margin-right: 5px;
  padding: 0px 20px;
  border-radius: 2px;
  position: relative;
  background: #219ebc;
}

.btnDateActive {
  background: #0e6a80 !important;
}

.font9 {
  font-size: 9px !important;
  position: relative;
  color: #686464;
  top: 12px;
}

.compareGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.Header {
  font-size: 20px;
  padding: 10px 20px;
  text-align: left;
  color: #fff !important;
  margin: 0 0 20.5px;
  background-color: var(--interactive-blue) !important;
}

.TabCorridor {
  font-size: 20px;
  padding: 4px;
  padding-top: 4px;
  text-align: left;
  border-radius: 0 !important;
  color: var(--interactive-blue) !important;
  margin: 0 0 20.5px;
  background-color: #ffffff00 !important;
  box-shadow: none !important;
  margin-top: 8px !important;
  padding-left: 0px;
  padding-bottom: 0px;
}

.TabCorridor li {
  font-size: 13px;
  color: #050505;
  opacity: 0.94;
}

.MuiBreadcrumbs-separator {
  display: flex;
  margin-left: 4px;
  user-select: none;
  margin-right: 4px;
}

.classesPaper {
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3) !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  margin: 0 111px 6px 19px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: var(--interactive-blue) !important;
}

.MuiSlider-markLabel {
  font-size: 9px !important;
}

.MuiSlider-mark {
  top: 18px;
}

.TabCorridor a {
  color: #000;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.TabCorridor a:hover {
  color: var(--primary_etalyc_blue) !important;
}

.MuiSlider-vertical .MuiSlider-markLabel {
  color: #000 !important;
  font-size: 9px !important;
}

.slideProper {
  margin-top: 3rem;
}

.activeDir {
  color: var(--secondary_sunset_orange) !important;
  border-bottom: 2px solid;
}

.cursurDir {
  cursor: pointer;
  margin-left: 0px;
}

.avgStopsDir {
  top: auto;
  font-size: 13px;
  margin-top: -27px;
  margin-left: -12%;
  position: absolute;
  transform-origin: 0 0;
  transform: rotate(-90deg);
}

.addDirection {
  text-align: center;
}

.avgStop {
  top: -35px;
  color: var(--interactive-blue);
  font-size: 12px;
  font-weight: bold;
  position: relative;
}

.mb5 {
  margin-bottom: 10px;
}

.orange {
  color: var(--secondary_sunset_orange) !important;
}

.addDirection p {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  color: orange;
  margin-bottom: 0;
  margin-left: 19px;
  position: relative;
  top: -9px;
}

.addDirection h6 {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  color: var(--interactive-blue);
  margin-bottom: 4px;
  margin-left: 19px;
  position: relative;
  top: -9px;
}

.currentDir {
  top: auto;
  font-size: 12px;
  margin-top: -4%;
  margin-left: 1%;
  position: absolute;
  transform-origin: 0 0;
  transform: rotate(-90deg);
  color: #3b94b0;
}

.xaxis {
  font-size: 12px;
  margin-bottom: 0;
  color: #3b94b0;
  text-align: center;
}

.mb0 {
  margin-bottom: 0px !important;
}

.backIntersection {
  float: right;
  color: #3b94b0;
  cursor: pointer;
}
.btnDateCOMPARE {
  position: absolute;
  top: 82px;
  top: auto;
  margin-top: -3px;
  padding: 1px 20px;
}

.timingPlanHeight {
  background: rgb(255, 255, 255);
  height: 445px;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3) !important;
}
.comparisionMinHeight {
  margin-top: 9px;
  min-height: 200px;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3) !important;
}
.boxShadow {
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3) !important;
}

.toogleDebique {
  position: absolute;
  top: 86px;
  right: 57.5%;
  z-index: 1;
  border: #ff9900;
  color: #3b94b0;
  padding: 0;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.toogleDebique .MuiSvgIcon-root {
  font-size: 22px;
  right: -3px;
  position: relative;
}

.labelPlan {
  margin-right: 12px;
  position: relative;
  top: -2px;
}
.debbuqueChart {
  width: 100%;
  height: 72.5px !important;
  padding: 4px;
}

.dPhaseP {
  position: relative;
  top: -8px;
}
.debbuqueText {
  color: #000;
  font-size: 10px;
  margin-top: -12px;
  position: absolute;
  margin-left: 11%;
}
.TimmingGridHeight {
  background: #fff;
  min-height: 447px;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3) !important;
}
.TimmingGridHeightComparision {
  margin-top: 9px;
  min-height: 200px;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3) !important;
}
.currentSpace {
  margin-right: 12px;
}
.currentSpace2 {
  margin-right: 8px;
}
.btnShowT {
  top: 6px;
  left: 17rem;
  border: none;
  color: #fff;
  border-radius: 2px;
  padding: 2px 10px;
  position: absolute;
  right: 15px;
}

.iconBtn {
  background: #3b94b0;
  color: #fff !important;
  position: absolute !important;
  right: 0 !important;
}

.backIntersection {
  float: right;
  color: #3b94b0;
  cursor: pointer;
}
.btnDateCOMPARE {
  position: absolute;
  top: 82px;
  top: auto;
  margin-top: -3px;
  padding: 1px 20px;
}

.timingPlanHeight {
  background: rgb(255, 255, 255);
  height: 445px;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3) !important;
}
.comparisionMinHeight {
  margin-top: 9px;
  min-height: 200px;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3) !important;
}
.boxShadow {
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3) !important;
}

.toogleDebique .MuiSvgIcon-root {
  font-size: 22px;
  right: -3px;
  position: relative;
}

.labelPlan {
  margin-right: 12px;
  position: relative;
  top: -2px;
}
.saltlakeChart {
  width: 100%;
  height: 52.7px !important;
  padding: 4px;
}

.dPhaseP {
  position: relative;
  top: -8px;
}
.saltlakeText {
  color: #000;
  font-size: 10px;
  text-align: center;
  margin-top: -12px;
}

.TimmingGridHeight {
  background: #fff;
  min-height: 447px;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3) !important;
}
.TimmingGridHeightComparision {
  margin-top: 9px;
  min-height: 200px;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3) !important;
}
.currentSpace {
  margin-right: 12px;
}
.currentSpace2 {
  margin-right: 8px;
}
.ecdPlot {
  height: 82.5vh;
  overflow-y: auto;
}

.ecdPlot .MuiSlider-markLabel {
  display: none;
  font-size: 9px !important;
}

.ecdPlot .MuiSlider-marked {
  margin-bottom: 0;
}

.ecdPlot .PrivateValueLabel-circle {
  width: 69px !important;
  height: 65px !important;
}

.timeECD {
  position: absolute;
  margin-top: -14px;
  margin-left: 16px;
}
.ECDTimeCheckbox {
  position: absolute;
  margin-top: -20px;
  right: -146px;
}

.texr-overflow-ellipse {
  overflow: hidden;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button-fixed {
  position: absolute !important;
  padding: 6px !important;
  margin-top: -12px !important;
  background: #fff !important;
  width: max-content !important;
  /* transition: 2s; */
  line-height: 19px !important;
  font-size: 9px !important;
  text-align: left;
  box-shadow: 2px 0 4px rgb(0 0 0 / 50%);
  border-radius: 4px;
  margin-left: -26px;
}
.button-fixed .dot {
  margin-right: 2px;
}

/* For SlownessGraph */
.slownessNote {
  margin-left: 18px;
  font-size: 12px;
  font-style: italic;
  color: #758283;
}
