.login {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr 1fr;
}
.logo {
  display: flex;
  flex-flow: column;
  align-items: center;
  background: #d7dfdf;
  justify-content: center;
}
.logo p {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
}
.form {
  width: 45%;
  display: flex;
  margin: auto;
  flex-flow: column;
  justify-content: center;
}
.form h1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 25.9083px;
  line-height: 31px;

  color: #000000;
}
.form p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12.9541px;
  line-height: 16px;

  color: rgba(0, 0, 0, 0.53);
}
.formBox {
  padding: 2rem 0;
}
.contactFormTitle {
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0;
}
.formBox input {
  width: 95%;
  padding: 0.9rem 8px;
  margin-bottom: 1rem;
  border-radius: 6.47706px;
  border: 0.809633px solid #b0bec0;
}
.submit {
  width: 100%;
  color: #fff;
  margin: 1rem 0;
  font-weight: 600;
  padding: 0.9rem 8px;
  background: #9caeb0;
  border-radius: 6.47706px;
  border: 1px solid transparent;
}
.submit:hover {
  background: #fff;
  color: #000000;
  border: 1px solid #9caeb0;
}
.error {
  color: red;
  font-size: 14px;
}
.linkColor {
  color: rgb(156, 174, 176);
  padding: 2px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
