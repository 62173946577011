/***
 * This file is used to   responsive for all screen  
 * Author : Pankaj Kumar
 * Created on: 23 May 2020
 */

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {}

/* Heighhhhh */

@media (min-width: 1281px) and (min-height:900px) {}

/* Heighh   80000000000 */

@media screen and (min-width: 800px) and (max-width: 1280px) {}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  .makeStyles-root-251 {
    height: 160px!important;
    text-align: center;
    margin-left: 30px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 700px) {
  .MuiBreadcrumbs-separator {
    margin-right: 0px!important;
  }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {}

#content-desktop {
  display: inline-flex;
}

#content-mobile {
  display: none;
}

@media (min-width: 768px) and (max-width: 1024px) {
  #content-desktop {
    display: none;
  }
  #content-mobile {
    display: inline-flex;
  }
  .timingBtnN {
    width: auto!important;
    margin: 0px!important;
    padding: 0px 7px!important;
    margin-left: 4px!important;
  }
  .wrapperTimingInside2 {
    grid-template-columns: 30% 70%!important;
  }
  .generateWrapper button {
    min-width: auto!important;
  }
  .wrapperHeaderB {
    padding: 0px 8px!important;
  }
}

/* For Large Destop */

@media screen and (min-width: 1080px) and (max-width: 1920px) {}