.addUsers {
  color: #fff !important;
  min-height: 100vh;
  background: 'gray';
}

.addUsersLight {
  color: #000 !important;
  min-height: 100vh;
  background: 'gray';
}

.MuiFormHelperText-contained {
  margin-left: 0px !important;
}

.InputParent .MuiInputBase-input {
  color: #fff !important;
  cursor: default !important;
  padding: 10px !important;
  border-radius: 12px !important;
}

.InputParent .MuiFormLabel-root {
  color: #fff !important;
  margin: 0px 4px !important;
}

.pt-20 {
  padding-top: 55px;
}

.img {
  float: right;
  width: 231px;
  height: 231px;
  text-align: right;
  border-radius: 50%;
  background: grey;
  margin-right: 42px;
  margin-top: 23px;
}

.img-selected {
  width: 231px;
  height: -webkit-fill-available !important;
  height: 231px !important;
  border-radius: 50%;
}

.clear-both {
  clear: both;
}

.text-upload {
  float: right;
  width: 230px !important;
  margin-top: 37px !important;
  margin-right: 50px !important;
}

.btn-submit {
  width: 250px;
  margin: auto !important;
  display: block !important;
  margin-top: 28px !important;
}

.btn-submit-disabled {
  width: 250px;
  pointer-events: none;
  background: #efefef;
  margin: auto !important;
  display: block !important;
  margin-top: 28px !important;
}

.heading-p {
  margin-bottom: 15px;
}

.logo {
  padding: 10px;
  margin-top: 10px;
}

/* //\\\\///\\\\////LOGIN\\\\////\\\\////\\\\ */

.login {
  color: #fff !important;
  min-height: 100vh;
  background: gray;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.makeStyles-container-7 {
  background: none !important;
}

.text-shadow {
  text-shadow: 3px 0 5px #000000;
}

.login p {
  background: red;
  padding: 5px;
  color: #fff !important;
  border-radius: 4px;
  margin: 0;
  margin-top: 3px;
}

/* //////\\\\\\\\\\/////////// Add Team\\\\\\\////////////\\\\\\\\////// */

.addTeamTop {
  margin-top: 3%;
}

.addTeam input {
  width: 200px;
}

.marginButtom20 {
  margin-bottom: 20px;
}

.addTeam {
  margin-bottom: 20px;
}

.btnAddsubmit {
  width: 250px;
  margin: 0 auto !important;
  display: block !important;
}

.marInput {
  margin-bottom: 8px !important;
}

.cursor {
  cursor: pointer;
}

.interSectionEdit {
  border: 1px solid;
  padding: 4px 12px;
  color: #fff;
  outline: none;
  background: transparent;
}

.interSection {
  border: none;
  color: #fff;
  background: transparent;
}

.popupH {
  margin-top: 15px;
}

.mp-0 {
  margin: 0 !important;
  padding: 0 !important;
}

.MuiCollapse-wrapper {
  min-width: 100%;
  overflow-x: auto;
}

.addInterSection {
  color: #fff;
  cursor: pointer;
  padding: 4px 12px;
  margin: 10px 5px;
  border-radius: 4px;
  margin-bottom: 12px 0;
  background: #303f9f;
}

.addInterSectiona {
  color: #fff;
  cursor: pointer;
  padding: 4px 12px;
  margin: 10px 4px;
  border-radius: 4px;
  margin-bottom: 12px 0;
  background: #303f9f;
  width: max-content;
}
.toggleUnassignedIntersection {
  position: absolute;
  right: 360px;
  top: 5px;
  color: #fff;
  cursor: pointer;
  padding: 4px 12px;
  margin: 10px 4px;
  border-radius: 4px;
  margin-bottom: 12px 0;
  background: #303f9f;
  width: max-content;
}

.addInterSectionRight {
  position: absolute;
  right: 170px;
  top: 5px;
  color: #fff;
  cursor: pointer;
  padding: 4px 12px;
  margin: 10px 4px;
  border-radius: 4px;
  margin-bottom: 12px 0;
  background: #303f9f;
  width: max-content;
}

.addCorridorSectionRight {
  position: absolute;
  right: 6px;
  top: 5px;
  color: #fff;
  cursor: pointer;
  padding: 4px 12px;
  margin: 10px 4px;
  border-radius: 4px;
  margin-bottom: 12px 0;
  background: #303f9f;
  width: max-content;
}

.text-right {
  text-align: right;
}
.colorT {
  color: #303f9f;
  font-weight: bold;
}
.borderP {
  margin: 12px 3px;
  border-radius: 4px;
  background: #303f9f;
  border: 1px solid #303f9f;
}
.borderP:hover {
  background: #303f9f;
}
.userName {
  top: 18px;
  right: 52px;
  color: #fff;
  position: inherit;
}
