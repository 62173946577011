/* Timing Plan  */

.timingPlanGraph {
  bottom: 0px;
  width: 100%;
  padding: 12px;
  min-height: 50vh;
  padding-bottom: 20px;
  background: #181818f0 !important;
}

.critItemChildTiming {
  width: 80%;
  float: right;
  padding: 10px;
  display: table-row;
  height: max-content;
  background: #1a1a1ac2;
}

.LegendButtonPosition {
  left: -192px;
  padding: 2px;
  font-size: 10px;
  position: absolute;
  background: #191919;
}

.LegendBtn {
  width: 20px;
  height: 20px;
  border: none;
  color: #fff;
  font-size: 12px;
  padding: 0px 7px;
  margin-right: 4px;
  border-radius: 50%;
}

.BrowsePlanBtn {
  position: absolute;
  float: right;
  right: 9px;
  margin: 6px;
  color: #fff;
  outline: none;
  font-size: 12px;
  padding: 2px 10px;
  margin-top: -35px;
  border-radius: 4px;
  background: #3f51b5;
  border: 1px solid #3f51b5;
}

.BtnTiming {
  border: none;
  color: #fff;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 4px;
  margin-right: 12px;
  background: #3f51b5;
}

.BtnClose {
  border: none;
  color: #fff;
  color: #f50057;
  position: relative;
  border-radius: 4px;
  background: transparent;
}

.titleP {
  margin-bottom: 0px;
  color: #fff;
  font-size: 12px;
}

.paperC {
  width: 100%;
  padding: 3px;
  height: 110px;
  overflow-y: auto;
  text-align: center;
  overflow-x: hidden;
}

/* direction positionnn */

.dirR {
  top: 54px;
  right: -3px;
  color: #fff;
  font-size: 12px;
  position: absolute;
  transform: rotate(-90deg);
}

.dirB {
  top: 65px;
  left: 4px;
  color: #fff;
  font-size: 12px;
  position: absolute;
  transform: rotate(-90deg);
}

.BtnCloseDialog {
  top: -16px;
  float: right;
  right: -20px;
  border: none;
  color: red;
  position: relative;
  background: transparent;
}

.fontSize12 {
  font-size: 12px !important;
}

.alignCenter {
  display: flex;
  align-items: center;
  width: 100%;
}

.showTimingCompare {
  padding: 99px 0px;
  text-align: center;
  min-height: 255px;
}

#map canvas {
  cursor: crosshair;
}

.editMapButton {
  z-index: 1;
  float: right;
  color: #fff;
  margin: 0px 4px;
  cursor: pointer;
  padding: 4px 10px;
  border-radius: 4px;
  background: #3f51b5;
}
.help-button {
  z-index: 1;
  float: right;
  margin: 0px 4px;
  cursor: pointer;
  border-radius: 4px;
  background: transparent;
}

.gridSystem {
  display: grid;
  grid-template-columns: 20% 40% 30%;
  grid-gap: 1rem;
  width: 100%;
}

.editMapSpan {
  color: #fff;
  margin-right: 28px;
  font-size: 16px;
}

.editMapInput {
  margin-bottom: 12px;
  border: none;
  border-radius: 4px;
}

.editMapDrop {
  padding: 0px !important;
  margin-bottom: 12px;
}

.editMapSave {
  z-index: 1;
  border: none;
  color: #fff;
  margin: 4px;
  cursor: pointer;
  padding: 4px 10px;
  border-radius: 4px;
  background: #3f51b5;
}

.bg-edit {
  color: #fff !important;
  background: #191a1aeb;
  padding: 12px;
  border-radius: 4px;
}

.popupInput {
  margin-left: 25px;
  margin-bottom: 12px;
}

.popupSave {
  margin-top: 12px;
  border: none;
  border-radius: 4px;
  padding: 4px 15px;
  background: #303f9f;
  color: #fff;
}

.leftPosition {
  position: absolute;
  left: 301px;
}
