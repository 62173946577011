@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'react-toastify/dist/ReactToastify.min.css';
@import 'react-datepicker/dist/react-datepicker.css';
body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  /* font-family: 'Lato', sans-serif!important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--layout-background);
  min-height: 100vh;
  /* font-size: 1vmax; */
  /* overflow: hidden; */
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0.2rem 0 !important;
  padding: 0;
}
.mt-2 {
  margin-top: 1rem;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.activebtn {
  background: var(--active-btn) !important;
  color: var(--active-btn-text) !important;
}
.activeHeader {
  border-bottom: 4px solid var(--border-color) !important;
  opacity: 1 !important;
  color: var(--navbar-active-text) !important;
}

.MuiTreeItem-label:hover {
  background-color: transparent !important;
}

.parent .react-datepicker__day-names,
.parent .react-datepicker__week {
  white-space: nowrap;
  display: flex;
  justify-content: space-around;
  padding: 0;
}
.parent .react-datepicker {
  width: 100%;
  border: none;
  background: var(--common-box-background) !important;
}
.parent .react-datepicker__header {
  text-align: center;
  background: var(--common-box-background) !important;
  /* border-bottom: 1px solid #aeaeae; */
  border: none;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.parent .react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: var(--event-text) !important;
  font-weight: bold;
  font-size: 0.944rem;
}
.parent .react-datepicker__month-container {
  float: initial !important;
}
.parent .react-datepicker__day-name,
.parent .react-datepicker__day,
.parent .react-datepicker__time-name {
  color: #bfbfbf;
  display: inline-block;
  width: 1.7rem;
  text-align: center;
  margin: 4px;
}
/* ddddddddddddddddddddddd */
.parent .react-datepicker__day--highlighted,
.parent .react-datepicker__month-text--highlighted,
.parent .react-datepicker__quarter-text--highlighted,
.parent .react-datepicker__year-text--highlighted {
  color: #fff !important;
}

.parent .react-datepicker__day--keyboard-selected,
.parent .react-datepicker__month-text--keyboard-selected,
.parent .react-datepicker__quarter-text--keyboard-selected,
.parent .react-datepicker__year-text--keyboard-selected {
  border-radius: 50%;
  height: 26px;
  width: 26px;
  border: 2px solid gray !important;
  /* background-color: #1d6012;
  color: #fff; */
}

.parent .react-datepicker__day--keyboard-selected {
  border: none !important;
  background-color: #fff;
}

.parent .react-datepicker__day--selected,
.parent .react-datepicker__day--in-selecting-range,
.parent .react-datepicker__day--in-range,
.parent .react-datepicker__month-text--selected,
.parent .react-datepicker__month-text--in-selecting-range,
.parent .react-datepicker__month-text--in-range,
.parent .react-datepicker__quarter-text--selected,
.parent .react-datepicker__quarter-text--in-selecting-range,
.parent .react-datepicker__quarter-text--in-range,
.parent .react-datepicker__year-text--selected,
.parent .react-datepicker__year-text--in-selecting-range,
.parent .react-datepicker__year-text--in-range {
  border-radius: 50%;
  height: 26px;
  width: 26px;
  border: 2px solid gray !important;
}

/* .parent .react-datepicker__day--keyboard-selected:hover,
.parent .react-datepicker__month-text--keyboard-selected:hover,
.parent .react-datepicker__quarter-text--keyboard-selected:hover,
.parent .react-datepicker__year-text--keyboard-selected:hover {
  border-radius: 0.3rem;
  background-color: #1d6012;
  color: #fff;
} */

.parent .react-datepicker__day--highlighted-custom-1 {
  /* border: 2px solid gray; */
  background-color: #b4161b;
  color: #fff;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  border: 2px solid transparent;
}
.parent .react-datepicker__day--highlighted-custom-1:hover {
  border-radius: 50%;
  height: 26px;
  width: 26px;
  background-color: #b4161b;
  color: #fff;
  border: 2px solid transparent;
}
.parent .react-datepicker__day--highlighted-custom-2 {
  border-radius: 50%;
  height: 26px;
  width: 26px;
  background-color: #6dd400;
  color: #fff;
  border: 2px solid transparent;
}
.parent .react-datepicker__day--highlighted-custom-2:hover {
  border-radius: 50%;
  background-color: #6dd400;
  color: #fff;
  border: 2px solid transparent;
}
.parent .react-datepicker__day--highlighted-custom-3 {
  border-radius: 50%;
  border: 2px solid gray;
  height: 26px;
  width: 26px;
  /* background-color: #6dd400;
  color: #fff; */
}
.parent .react-datepicker__day--highlighted-custom-3:hover {
  border-radius: 50%;
  border: 2px solid gray;
  height: 26px;
  width: 26px;
  /* background-color: #6dd400;
  color: #fff; */
}

/* .parent .react-datepicker__day--highlighted-custom-1{
  border-radius: 0.3rem;
  background-color: #B4161B;
  color: #fff;
}
.parent .react-datepicker__day--highlighted-custom-2{
  border-radius: 0.3rem;
  background-color: #6dd400;
  color: #fff;
} */

/* Events css  */

.tooltiptext p {
  margin-bottom: -2px !important;
  font-size: 10px !important;
  text-align: center;
  padding: 0px 2px;
}

.tooltiptext {
  background: #2f3330 !important;
  color: #fff;
  /* border-radius: 6px; */
  padding: 5px 2px;
  position: absolute;
  z-index: 1;
  margin-left: -32px;
  min-width: 26px;
  cursor: pointer;
}

.tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #2f3330 transparent transparent transparent;
}

.tooltiptext {
  visibility: visible;
}

.tooltiptextShow p {
  margin-bottom: -2px !important;
  font-size: 10px !important;
  text-align: center;
  padding: 0px 2px;
}

.tooltiptextShow {
  background: #2f3330 !important;
  color: #fff;
  /* border-radius: 6px; */
  padding: 5px 2px;
  position: absolute;
  z-index: 1;
  margin-left: -32px;
  min-width: 26px;
  cursor: pointer;
}

.tooltiptextShow::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 22%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #2f3330 transparent transparent transparent;
}

.tooltiptextShow {
  visibility: visible;
}
.sliderBtn {
  position: absolute;
  left: 71%;
  top: -16px;
  background: #d7dfdf;
  border: none;
  border-radius: 10px;
  padding: 2px 1rem;
}

/* Comparison Date */
.react-datepicker__input-container input {
  width: 95%;
  outline: none;
  font-size: 14px;
  border-radius: 4px;
  padding: 7px 8px !important;
  border: 1px solid #b4b4b4;
}
.update-date-range .react-datepicker__input-container input {
  width: 92% !important;
}
h6 {
  color: var(--text-header);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
}

.eventCalender .react-datepicker {
  width: 100%;
}
.eventCalender .react-datepicker__month-container {
  width: 100%;
}
.mapboxgl-ctrl-attrib-button {
  display: none;
}
/* Create Event Date Picker */
.create-event .react-datepicker__input-container input {
  width: 95%;
  outline: none;
  font-size: 14px;
  border-radius: 4px;
  padding: 0px !important;
  border: 1px solid #b4b4b4;
}
/* Update data for city Range Picker */
.update-date-range .react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}
.update-date-range .react-datepicker__input-container input {
  outline: none;
  /* font-size: 14px; */
  border-radius: 4px;
  padding: 6px !important;
  border: 1px solid #b4b4b4;
  /* For dark theme */
  background: #fff;
}

/* .update-date-range .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #3dcc4a;
  color: black;
} */

.update-date-range .react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  background-color: #3dcc4a !important;
}
/* To not selects dates which are disabled in city calender */
.update-date-range .react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  /* cursor: default; */
  color: #ccc;
  user-select: none;
  background: transparent !important;
}
