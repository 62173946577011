:root {
  --background: #c4ced0;
  --main-background: #ebefef;
  --text-header: black;
  --text-paragraph: rgba(0, 0, 0, 0.4);
  --accent: purple;
  --common-box-background: #fff;
  --active-btn: rgba(232, 238, 238, 0.65);

  --active-btn-text: #545d5e;
}
[data-theme='dark'] {
  --background: #071a2f;
  --main-background: #132f4c;
  --text-header: #ff6028;
  --text-paragraph: grey;
  --accent: darkred;
  --common-box-background: #fff;
  --active-btn: #27496d;
  --active-btn-text: #fff;
}
.commonBox {
  padding: 10px;
  /* background: #fff; */
  background: var(--common-box-background);
  border-radius: 0.5rem;
}
.textCenter {
  text-align: center;
}
.commonHeader {
  color: var(--text-header);
  font-size: 16px;
  font-weight: 700;
}
.commonParagraph {
  font-weight: 500;
  font-size: 12px;
  color: var(--text-paragraph);
}
.cHeight100 {
  height: 100%;
}
.error {
  font-size: 12px;
  padding: 4px;
  color: red;
}
.btn {
  color: #fff;
  padding: 8px 2rem;
  border: transparent;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
}
.lll {
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  height: 100%;
  /* display: flex; */
  overflow: auto;
  position: fixed;
  text-align: center;
  /* justify-content: center; */
  /* background-color: rgba(0, 0, 0, 0.4); */
}
