/* Dashboard Components */
.mapStyle {
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
}

#map {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
}

.marker {
	background-size: cover;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	cursor: pointer;
	background-color: #fff;
}

.mapboxgl-popup {
	max-width: 200px;
}

.mapboxgl-popup-content {
	position: relative;
	color: #fff;
	border-radius: 3px;
	pointer-events: auto;
	background: #343433 !important;
	padding: 10px 10px 3px !important;
	border: 1px solid #928f8f;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.mapboxgl-popup-content h6 {
	font-size: 14px !important;
	margin-top: 7px;
}

.mapboxgl-popup-close-button {
	color: #fff !important;
	font-size: 22px !important;
}

.textWeek {
	bottom: 6px;
	right: 52%;
	color: #fff;
	font-size: 12px;
	text-align: center;
	position: absolute;
}

.btnActionL {
	border: none;
	color: #fff;
	font-size: 12px;
	padding: 0px 7px;
	margin-right: 4px;
	border-radius: 50%;
	width: 20px;
	height: 20px;
}

.spanLan {
	display: block;
	font-size: 12px;
	text-align: left;
	margin-left: 17px;
	line-height: 30px;
	font-weight: normal;
}

.pLan {
	display: block;
	font-size: 14px;
	text-align: left !important;
	margin-left: 17px;
	font-weight: normal;
}

.cyLength {
	position: relative;
	font-size: 12px;
	left: 65px;
	color: #fff;
	top: -22px;
}

.showSpanCorridor {
	color: #fff;
	font-size: 14px;
	background: #3f51b5;
	padding: 2px 10px;
	border-radius: 4px;
}

.ml-12 {
	margin-left: 20px;
}

.titleNoData {
	top: 50%;
	font-size: 20px;
	position: absolute;
	margin-bottom: 0px;
	color: #e1e17d;
}

.MuiDialog-paperWidthLg {
	max-width: 1280px;
	min-width: 100%;
	height: 100%;
	background-color: #1b1b1be6 !important;
}

.flexC {
	display: flex;
	align-items: center;
}

.endTime {
	top: 20px;
	width: 100%;
	margin: auto;
	font-size: 7px;
	display: block;
	text-align: center;
	position: absolute;
}

.startTime {
	position: absolute;
	text-align: center;
	margin: auto;
	display: block;
	width: 100%;
	bottom: 2px;
}

.endTimeR {
	position: absolute;
	text-align: center;
	margin: auto;
	display: block;
	width: 100%;
	top: 2px;
}

.textWeek {
	bottom: 6px;
	right: 52%;
	color: #fff;
	font-size: 12px;
	text-align: center;
	position: absolute;
}

.btnActionL {
	border: none;
	color: #fff;
	font-size: 12px;
	padding: 0px 7px;
	margin-right: 4px;
	border-radius: 50%;
	width: 20px;
	height: 20px;
}

.spanLan {
	display: block;
	font-size: 12px;
	text-align: left;
	margin-left: 17px;
	line-height: 30px;
	font-weight: normal;
}

.pLan {
	display: block;
	font-size: 14px;
	text-align: left !important;
	margin-left: 17px;
	font-weight: normal;
}

.buttonPositionGraph {
	position: relative;
	left: -180px;
	font-size: 10px;
	top: -10px;
}

.cyLength {
	position: relative;
	font-size: 12px;
	left: 65px;
	color: #fff;
	top: -22px;
}

.showSpanCorridor {
	color: #fff;
	font-size: 14px;
	background: #3f51b5;
	padding: 2px 10px;
	border-radius: 4px;
}

.paperMinHeight {
	min-height: 30vh;
}

.MuiDialog-paperScrollPaper {
	display: flex;
	max-height: inherit !important;
	flex-direction: column;
}

.MuiIconButton-colorPrimary {
	color: #fff !important;
}

.editProgress {
	margin: 0;
	right: 2px;
	float: right;
	padding: 12px;
	color: #fff;
	text-align: right;
	font-size: 1.3rem;
	position: absolute;
	background: #191a1a;
}

.loadingg {
	width: 30px !important;
	height: 30px !important;
	position: relative;
	top: 12px;
	left: 12px;
}

.cursor-pointer {
	cursor: pointer;
}

.uploadLabel {
	padding: 10px 0px !important;
	color: #fff !important;
}

button:focus {
	outline: none !important;
}

button {
	outline: none !important;
	cursor: pointer;
}

button:hover {
	opacity: 1 !important;
	transition: 0.3s;
}

.button:after {
	content: '';
	background: gray;
	display: block;
	position: absolute;
	padding-top: 300%;
	padding-left: 350%;
	margin-left: -20px !important;
	margin-top: -120%;
	opacity: 0;
	transition: all 0.8s;
}

.button:active:after {
	padding: 0;
	margin: 0;
	opacity: 1;
	transition: 0s;
}

.pp .PrivateValueLabel-circle-77 {
	display: flex;
	transform: rotate(0) !important;
	align-items: center;
	justify-content: center;
	background-color: currentColor;
	position: relative;
	top: 59px;
	border-radius: 49px !important;
	width: 58px !important;
	height: auto !important;
	padding: 4px;
	left: -12px;
}

.pp .PrivateValueLabel-label-78 {
	color: #fff;
	padding: 0;
	transform: rotate(0) !important;
}

.activeSidebar {
	opacity: 1 !important;
	background: var(--layout-background);
	color: var(--active-text-label) !important;
	border-radius: 10px 0px 0px 10px;
}

.activeAdminSidebar {
	opacity: 1 !important;
	background: #fff;
	color: #2a6778 !important;
	border-radius: 10px 0px 0px 10px;
}

.map-container {
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
}

.mapboxgl-ctrl-top-right {
	display: none !important;
}

#geocoder-container {
	background-color: #fff;
	padding: 5px;
	border-radius: 4px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

#geocoder-container input[type="text"] {
	background-image: none;
	width: 260px;
	padding: 8px;
	border: none;
	border-radius: 4px;
	background-image: none;
}

.mapboxgl-ctrl-geocoder--icon {
	display: none !important;
}